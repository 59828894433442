import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import { useCurrentLang } from 'hooks/useCurrentLang';

import { Container, Layout } from 'layout';

import { NotFoundQueryResult } from './model';

const NotFoundPage = () => {
  const {
    currentLang: [currentLangCode],
  } = useCurrentLang();

  const {
    allPageNotFound: { nodes },
    pageNotFound,
  }: NotFoundQueryResult = useStaticQuery(graphql`
    {
      allPageNotFound {
        nodes {
          lang
          text
        }
      }
      pageNotFound {
        text
      }
    }
  `);

  const { text } = nodes?.find((item) => item.lang === currentLangCode) ||
    pageNotFound || { text: '' };

  return (
    <Layout>
      <section className="not-found container-wrapper">
        <div className="not-found__holder">
          <Container fluid className="not-found__content">
            <div className="not-found__content-text">
              <p>{text}</p>
            </div>
          </Container>
        </div>
      </section>
    </Layout>
  );
};

export default NotFoundPage;
